<template>
  <v-form ref="form" v-model="valid">
    <div class="mt-10">
      <v-row class="text-center">
        <v-col cols="12">
          <v-card
            class="mx-auto"
            style="max-width: 700px; height: auto; width: 95%"
          >
            <v-card-title class="d-flex justify-center">
              <v-row>
                <v-col cols="12">
                  <span>ตะกร้าใส่ล็อตเตอรี่</span>
                </v-col>

                <v-col cols="12">
                  <span
                    v-if="selectedItems.length"
                    style="font-size: 40px; color: #ff0000"
                    >{{ formatTime(countdown) }} นาที</span
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-carousel
              :style="
                $vuetify.breakpoint.smAndUp ? 'height: 300px' : 'height: 220px'
              "
              :continuous="false"
            >
              <v-carousel-item
                v-for="(item, index) in carouselItems"
                :key="index"
                cover
              >
                <v-card>
                  <img
                    :src="item.urlImage"
                    style="width: 100%; height: 100%; object-fit: cover"
                    alt="Carousel Item"
                  />
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
        <v-col cols="12" class="mt-n4">
          <v-card
            class="mx-auto pa-4"
            style="max-width: 700px; height: auto; width: 95%"
          >
            <!-- <v-card-title class="d-flex justify-center">
          <span>รางวัลสูงสุด 30 ล้านบาท</span>
        </v-card-title> -->
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" class="text-start mt-4">
                <span style="font-size: 20px">รายการลอตเตอรี่</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-start mt-n2">
                <v-card
                  class="pa-4"
                  outlined
                  style="
                    border-color: rgb(190, 189, 194);
                    border-width: 1px;
                    border-style: solid;
                  "
                  elevation="0"
                >
                  <v-row v-for="(item, index) in selectedItems" :key="index">
                    <v-col cols="8">
                      <v-btn
                        small
                        color="red"
                        style="min-width: 32px; width: 32px; height: 32px"
                        @click="deleteLottery(index)"
                      >
                        <v-icon color="white">mdi-close</v-icon>
                      </v-btn>
                      &nbsp; {{ item.lotteryNo }}
                      {{ item.setAmt > 1 ? "(เลขชุด)" : "(เลขเดี่ยว)" }}
                      <span style="color: rgb(190, 189, 194)"
                        >x {{ item.setAmt }}</span
                      >
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <!-- <span>{{ item.setAmt * (80 + customPrice) }}.00 บาท</span> -->
                      <span
                        >{{
                          item.setAmt < 3
                            ? item.setAmt * (customPrice + 80)
                            : item.setAmt == 3
                            ? 350
                            : item.setAmt == 10
                            ? item.setAmt * (customPrice10 + 80)
                            : item.setAmt * (customPrice2 + 80)
                        }}.00 บาท</span
                      >
                      <!-- <span
                        >{{
                          item.setAmt < 3
                            ? item.setAmt * (customPrice + 80)
                            : item.setAmt == 3
                            ? item.setAmt * (customPrice1 + 80)
                            : item.setAmt * (customPrice2 + 80)
                        }}.00 บาท</span
                      > -->
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-start mt-n2">
                <v-card
                  class="pa-4"
                  style="background-color: #dde4f0"
                  elevation="0"
                >
                  <v-row>
                    <v-col cols="8"> ค่าลอตเตอรี่ฉบับละ </v-col>
                    <v-col cols="4" class="text-end">
                      <span>80.00 บาท</span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n4">
                    <v-col cols="8"> จำนวน </v-col>
                    <v-col cols="4" class="text-end">
                      <span>{{ setAmtAll }} ใบ</span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n4">
                    <v-col cols="8">
                      <span style="font-weight: bold">รวมราคาล็อตเตอรี่</span>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span style="font-weight: bold"
                        >{{ setAmtAll * 80 }}.00 บาท</span
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row>
              <v-col cols="12" class="text-start mt-4">
                <span style="font-size: 20px"
                  >รายการการให้บริการรวมค่าเช่าพื้นที่</span
                >
              </v-col>
              <v-col cols="12" class="text-start mt-n2">
                <v-card
                  class="pa-4"
                  style="background-color: #dde4f0"
                  elevation="0"
                >
                  <!-- <v-row>
                    <v-col cols="8">
                      ค่าเช่าพื้นที่จัดเก็บช่องละ
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span>{{ customPrice.toFixed(2) }} บาท</span>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="8">
                      ชุด1ใบราคารวมชุดละ
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span>{{ (customPrice + 80).toFixed(2) }} บาท</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      ชุด2ใบราคารวมชุดละ
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span>{{ ((customPrice + 80) * 2).toFixed(2) }} บาท</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      ชุด3ใบราคารวมชุดละ
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <!-- <span>{{ customPrice1.toFixed(2) }} บาท</span> -->
                      <span> 350.00 บาท</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      ชุด5ใบราคารวมชุดละ
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span
                        >{{ ((customPrice2 + 80) * 5).toFixed(2) }} บาท</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      ชุด10ใบราคารวมชุดละ
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span
                        >{{ ((customPrice10 + 80) * 10).toFixed(2) }} บาท</span
                      >
                    </v-col>
                  </v-row>
                  <!-- <v-row class="mt-n4">
                    <v-col cols="8"> จำนวน </v-col>
                    <v-col cols="4" class="text-end">
                      <span>{{ setAmtAll }} </span>
                    </v-col>
                  </v-row> -->
                  <!-- <v-row class="mt-n4">
                    <v-col cols="8">
                      <span style="font-weight: bold">ยอดรวมทั้งสิ้น</span>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span style="font-weight: bold"
                        >{{ totalPriceAll.toFixed(2) }} บาท</span
                      >
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <span style="font-weight: bold"
                        >{{ (setAmtAll * customPrice).toFixed(2) }} บาท</span
                      >
                    </v-col>
                  </v-row> -->
                </v-card>
              </v-col>
            </v-row>
            <v-divider
              v-if="userData.user.userType == 'ADMIN'"
              class="mt-4"
            ></v-divider>
            <v-row v-if="userData.user.userType == 'ADMIN'">
              <v-col cols="12" class="text-start mt-4">
                <span style="font-size: 20px">ชื่อและเบอร์โทร (ลูกค้า)</span>
              </v-col>
              <v-col cols="12" class="text-start mt-n2">
                <v-card
                  class="pa-4"
                  style="background-color: #fff"
                  elevation="0"
                >
                  <v-row>
                    <v-col cols="12" class="mt-n6 mb-n6">
                      <v-checkbox
                        v-model="isBuyforAdmin"
                        label="ซื้อให้ตัวเอง (ADMIN)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <span>ชื่อ ลูกค้า</span>
                      <v-text-field
                        v-model="customerFirstname"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="required"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>นามสกุล ลูกค้า</span>
                      <v-text-field
                        v-model="customerLastname"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="required"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>เบอร์โทร ลูกค้า</span>
                      <v-text-field
                        v-model="customerTel"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-start mt-n2">
                <v-card
                  class="pa-4"
                  style="background-color: #4ca3ad"
                  elevation="0"
                >
                  <v-row>
                    <div class="single-line-row">
                      <v-checkbox
                        v-model="checkbox"
                        class="ml-2"
                        :size="100"
                        color="black"
                      ></v-checkbox>
                      <span style="font-size: 16px; color: #fff">
                        ข้าพเจ้าต้องการเช่าพื้นที่จัดเก็บลอตเตอรี่
                      </span>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="mt-2 pa-4">
              <v-col cols="8" class="text-start">
                <span style="font-weight: bold">ยอดรวมทั้งสิ้น</span>
              </v-col>
              <!-- <v-col cols="4" class="text-end">
                <span style="font-weight: bold"
                  >{{ (setAmtAll * (80 + customPrice)).toFixed(2) }} บาท</span
                >
              </v-col> -->
              <v-col cols="4" class="text-end">
                <span style="font-weight: bold"
                  >{{ totalPriceAll.toFixed(2) }} บาท</span
                >
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="12">
                <v-btn
                  @click="goToPayment()"
                  :disabled="isAccept"
                  block
                  color="red"
                  :loading="loading"
                  ><span :style="isAccept ? 'color: black' : 'color: #fff'"
                    >ชำระเงิน</span
                  >
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="12">
                <a href="/" class="custom-link">เลือกซื้อลอตเตอรี่ต่อ</a>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { Encode, Decode } from "@/services";

export default {
  components: {},

  data: () => ({
    valid: false,
    loading: false,
    isBuyforAdmin: false,
    customerFirstname: "",
    customerLastname: "",
    customerTel: "",

    countdown: 0,
    timerInterval: null,

    checkbox: false,
    isAccept: true,
    setAmtAll: 0,

    selectedItems: [
      // { lotteryNo: "111111", setAmt: 1 },
      // { lotteryNo: "222222", setAmt: 2 },
      // { lotteryNo: "333333", setAmt: 3 },
      // { lotteryNo: "333333", setAmt: 5 },
      // { lotteryNo: "333333", setAmt: 10 },
    ],
    userData: [],
    lotteries: [],

    required: [(v) => !!v || "กรุณากรอกข้อมูล"],

    customPrice: 0,
    customPrice1: 0,
    customPrice2: 0,
    customPrice10: 0,
    totalPriceService: 0,
    totalPriceAll: 0,
  }),

  watch: {
    checkbox(val) {
      if (val) {
        this.isAccept = false;
      } else {
        this.isAccept = true;
      }
    },
    isBuyforAdmin(val) {
      console.log(val);
      if (val == true) {
        this.customerFirstname = this.userData.user.firstName;
        this.customerLastname = this.userData.user.lastName;
        this.customerTel = this.userData.user.telNumber;
      } else {
        this.customerFirstname = "";
        this.customerLastname = "";
        this.customerTel = "";
      }
    },
  },
  computed: {
    carouselItems() {
      return this.selectedItems.flatMap((item) =>
        Array(item.setAmt).fill(item)
      );
    },
    formatText() {
      const rawText = this.$t(this.messageKey);
      return rawText.replace(/\n/g, "<br>");
    },
  },

  async created() {
    const encodedUserLogin = localStorage.getItem("LEKSUAYMILLOINUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }

    const encodedRefNumber = localStorage.getItem("refNumber");
    if (encodedRefNumber !== null) {
      try {
        const decodedRefNumber = Decode.decode(encodedRefNumber);
        const cleanedRefNumber = decodedRefNumber.replace(/^"|"$/g, "");
        this.userData.refNumber = cleanedRefNumber;
      } catch (error) {}
    }

    const storedItems = localStorage.getItem("selectedItems");
    this.selectedItems = storedItems ? JSON.parse(storedItems) : [];

    this.calculateSetAmtAll();

    this.initializeCountdown();
    this.startCountdown();

    await this.getCustomPrice();
    await this.calculateTotalPrice();
  },
  methods: {
    async calculateTotalPrice() {
      this.loading = true;
      this.selectedItems.forEach((element) => {
        if (element.setAmt < 3) {
          this.totalPriceService += element.setAmt * this.customPrice;
          this.totalPriceAll += element.setAmt * (80 + this.customPrice);
        } else if (element.setAmt == 3) {
          // this.totalPriceService += element.setAmt * this.customPrice1;
          this.totalPriceService += 110;
          // this.totalPriceAll += element.setAmt * (80 + this.customPrice1);
          this.totalPriceAll += 350;
        } else if (element.setAmt == 10) {
          this.totalPriceService += element.setAmt * this.customPrice10;
          this.totalPriceAll += element.setAmt * (80 + this.customPrice10);
        } else {
          this.totalPriceService += element.setAmt * this.customPrice2;
          this.totalPriceAll += element.setAmt * (80 + this.customPrice2);
        }
      });
      this.loading = false;
    },
    async getCustomPrice() {
      this.loading = true;
      const responseCustomPirce = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/CUSTOM_PRICE`
      );
      this.customPrice = parseInt(responseCustomPirce.data.data.value1);
      // const responseCustomPirce1 = await this.axios.get(
      //   `${process.env.VUE_APP_API}/configs/findByType/AGENT_PRICE_5`
      // );
      // this.customPrice1 = parseInt(responseCustomPirce1.data.data.value1);
      const responseCustomPirce2 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/CUSTOM_PRICE_2`
      );
      this.customPrice2 = parseInt(responseCustomPirce2.data.data.value1);

      const responseCustomPirce10 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/CUSTOM_PRICE_10`
      );
      this.customPrice10 = parseInt(responseCustomPirce10.data.data.value1);
      this.loading = false;
    },
    startCountdown() {
      this.timerInterval = setInterval(async () => {
        if (this.countdown > 0) {
          this.countdown--;
          if (this.countdown === 1) {
            await this.$swal.fire({
              icon: "warning",
              html: "หมดเวลาตะกร้าสินค้า",
              showConfirmButton: true,
            });
            localStorage.removeItem("selectedItems");
            localStorage.removeItem("storedDatetime");
            this.$router.go(0);
          }
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    async stopCountdown() {
      clearInterval(this.timerInterval);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    },
    initializeCountdown() {
      const storedDatetime = localStorage.getItem("storedDatetime");
      if (storedDatetime) {
        const storedTime = new Date(storedDatetime).getTime();
        const currentTime = new Date().getTime();
        const differenceInSeconds = Math.floor(
          (currentTime - storedTime) / 1000
        );
        this.countdown = Math.max(900 - differenceInSeconds, 0);
      } else {
        this.countdown = 0;
      }
    },
    async checkLotterySold() {
      let allResponsesAreTrue = true;
      const itemsToRemove = [];
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/lottery/checkStock?lotteryNo=${element.lotteryNo}`
        );
        if (response.data.data === false) {
          await this.$swal.fire({
            icon: "warning",
            html: "มีล็อตเตอรี่ถูกจองไปแล้ว<br/>ระบบกำลังตรวจสอบและลบใบที่ซ้ำ<br/>กรุณาตรวจสอบหมายเลขอีกครั้ง",
            showConfirmButton: false,
            timer: 3000,
          });
          itemsToRemove.push(index);
          allResponsesAreTrue = false;
        }
      }
      itemsToRemove.reverse().forEach((index) => {
        this.selectedItems.splice(index, 1);
      });
      if (allResponsesAreTrue) {
        this.calculateSetAmtAll();
        await this.createTransaction();
      } else {
        localStorage.setItem(
          "selectedItems",
          JSON.stringify(this.selectedItems)
        );
        setTimeout(() => {
          this.$router.go(0);
        }, 500);
      }
    },
    async goToPayment() {
      this.loading = true;
      if (this.selectedItems.length) {
        this.$refs.form.validate();

        if (this.valid) {
          await this.checkLotterySold();
        } else {
          await this.$swal.fire({
            icon: "warning",
            html: "แอดมินต้องกรอก<br/>ชื่อ-นามสกุลและเบอร์โทรลูกค้า",
            showConfirmButton: true,
          });
          this.loading = false;
        }
      } else {
        await this.$swal
          .fire({
            icon: "warning",
            html: "ไม่มีสินค้าในตะกร้า",
            showConfirmButton: true,
          })
          .then(() => {
            this.$router.push("/");
          });
      }
    },
    async createTransaction() {
      const paynamcartsentData = {
        isDelivery: "0",
        address: {
          address: this.userData.user.deliveryaddress,
          subDistrict: null,
          district: null,
          province: this.userData.user.province,
          postcode: this.userData.user.postcode,
        },
      };
      await this.beforeTransaction(this.selectedItems, paynamcartsentData);
    },
    async beforeTransaction(carts, paynamcartsentData) {
      this.loading = true;
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("LEKSUAYMILLOINUser"))
      );

      var agent = localStorage.getItem("agentCode");
      var agentData;
      var agentId = null;
      if (agent && agent !== "undefined") {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/agents/` + agent
        );
        agentData = response.data.data;

        agentId = agentData.id;
      }
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      //
      //
      for (let i = 0; i < carts.length; i++) {
        const cart = carts[i];
        const data = {
          lotteryNo: cart.lotteryNo,
          lotteryId: cart.id,
          agentId: agentId,
        };
        const existingIndex = this.lotteries.findIndex(
          (item) => item.lotteryId === data.lotteryId
        );
        if (existingIndex === -1) {
          this.lotteries.push(data);
        }
      }

      if (this.userData.user.userType == "ADMIN") {
        const data = {
          // totalPrice: this.setAmtAll * (80 + this.customPrice),
          totalPrice: this.totalPriceAll,
          waranty: paynamcartsentData.waranty,
          isDelivery: paynamcartsentData.isDelivery,
          deliveryAddress: paynamcartsentData.address,
          status: "PENDING",
          deliveryStatus: "WAITING",
          userId: this.userData.user.id,
          lotteries: this.lotteries,
          slipImg: "",
          agentId: 10,
          totalAmt: this.setAmtAll,
          byAdmin: 1,
          byAdmidId: this.userData.user.id,
          customerFirstname: this.customerFirstname,
          customerLastname: this.customerLastname,
          customerTel: this.customerTel.trim(),
          refNumber:
            this.userData.refNumber != undefined &&
            this.userData.refNumber != this.userData.user.telCode
              ? this.userData.refNumber
              : null,
        };

        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/transaction/create`,
          data,
          auth
        );

        if (response.data.response_status == "ERROR") {
          this.$swal.fire({
            icon: "warning",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.trasactionId = response.data.data.id;
          localStorage.setItem("trasactionId", this.trasactionId);
          await this.$swal.fire({
            icon: "success",
            html: "จองสำเร็จ<br/>กรุณาแนบสลิปโอนชำระใน 1 ชม.",
            showConfirmButton: false,
            timer: 3000,
          });
          const selectedItems = localStorage.getItem("selectedItems");
          localStorage.setItem("paymentItems", selectedItems);
          localStorage.removeItem("selectedItems");
          localStorage.removeItem("storedDatetime");

          const currentTime = new Date().toISOString();
          localStorage.setItem("paymentDatetime", currentTime);

          await this.$router.push("/payment");
        }
      } else {
        const data = {
          // totalPrice: this.setAmtAll * (80 + this.customPrice),
          totalPrice: this.totalPriceAll,
          waranty: paynamcartsentData.waranty,
          isDelivery: paynamcartsentData.isDelivery,
          deliveryAddress: paynamcartsentData.address,
          status: "PENDING",
          deliveryStatus: "WAITING",
          userId: this.userData.user.id,
          lotteries: this.lotteries,
          slipImg: "",
          agentId: 10,
          totalAmt: this.setAmtAll,
          refNumber:
            this.userData.refNumber != undefined &&
            this.userData.refNumber != this.userData.user.telCode
              ? this.userData.refNumber
              : null,
        };

        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/transaction/create`,
          data,
          auth
        );

        if (response.data.response_status == "ERROR") {
          this.$swal.fire({
            icon: "warning",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.trasactionId = response.data.data.id;
          localStorage.setItem("trasactionId", this.trasactionId);
          await this.$swal.fire({
            icon: "success",
            html: "จองสำเร็จ<br/>กรุณาแนบสลิปโอนชำระใน 1 ชม.",
            showConfirmButton: false,
            timer: 3000,
          });
          const selectedItems = localStorage.getItem("selectedItems");
          localStorage.setItem("paymentItems", selectedItems);
          localStorage.removeItem("selectedItems");
          localStorage.removeItem("storedDatetime");

          const currentTime = new Date().toISOString();
          localStorage.setItem("paymentDatetime", currentTime);

          await this.$router.push("/payment");
        }
      }
      this.loading = false;
    },

    calculateSetAmtAll() {
      this.setAmtAll = this.selectedItems.reduce(
        (total, item) => total + item.setAmt,
        0
      );
    },
    async deleteLottery(index) {
      this.selectedItems.splice(index, 1);
      localStorage.setItem("selectedItems", JSON.stringify(this.selectedItems));
      await this.$router.go(0);
      // this.calculateSetAmtAll();

      if (!this.selectedItems.length) {
        localStorage.removeItem("selectedItems");
        localStorage.removeItem("storedDatetime");
      }
    },
  },
};
</script>

<style scoped>
.single-line-row {
  display: flex;
  align-items: center;
}

.custom-link {
  color: black;
  transition: color 0.3s; /* Add a smooth transition effect */
}

.custom-link:hover {
  color: #4ca3ad; /* Change to your primary color */
}
</style>





